* {
	padding: 0;
	margin: 0;
}
table {
	width: 100%;
	border-spacing: 0;
}
p {
	margin: 0;
	line-height: 1.4;
}
