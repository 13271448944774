@use '../sass/abstracts/' as *;
// @use '../'

.main {
	padding-left: 250px;
	overflow-x: hidden;
	background-color: ghostwhite;

	// @include tablet {
	// 	padding-left: 0;
	// 	width: 100vw;
	// }
	@media only screen and (max-width: 900px) {
		padding-left: 0;
		width: 100vw;
	}

	&__content {
		min-height: 100vh;
		padding: $spacing;
		transition: all 0.5s ease-in-out;

		// @include tablet {
		// 	padding: $mobileSpacing;
		// 	position: relative;
		// 	background-color: $bodyBg;
		// 	z-index: 100;
		// }
		@media only screen and (max-width: 1200px)
		 {
    padding: 24px;
    position: relative;
    background-color: #F3F4FA;
    z-index: 100;
}
	}
}

.sidebar-open .main__content {
	height: 100vh;
	overflow: hidden;
	border-top-left-radius: 30px;
	border-bottom-left-radius: 30px;
	transform: scale(0.8) translateX(50%);
	transition: all 0.5s ease-in-out;
}
