$bodyBg: #F3F4FA;
$mainBg: #fff;
$mainColor: #8624DB;
$txtColor: #4E4D4F;
$purple: #8624DB;
$orange: #FF9066;
$white: #fff;
$black: #000;
$green: #4CAF50;
$red: #DB190C;

$fontFamily: 'Roboto', sans-serif;

$fontSizeNormal: 1.125rem;
$fontSizeMedium: 1.25rem;
$fontSizeLarge: 1.5625rem;

$sideBarWidth: 384px;
$sideBarLogoHeight: 171px;
$spacing: 48px;
$mobileSpacing: 24px;
$borderRadius: 30px;

$mobile-width: 600px;
$tablet-width: 1366px;
